import React from "react"
import { Box, Button, Flex, Stack } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  Container,
  Layout,
  Main,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import { SecondaryPagesHero, Seo } from "../../../components/elements"

const Center = ({ data, pageContext }) => {
  const page = data.datoCmsCenter
  const {
    breadcrumb: { crumbs },
  } = pageContext

  
  return (
    <Layout>
      <Seo title={page.centerName} />

      <SecondaryPagesHero page={page}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={page.title}
        />
      </SecondaryPagesHero>

      <Container width="full">
        <Stack
          direction={["column", "column", "row"]}
          spacing={12}
          py={[9, 9, 12, 16]}
        >
          <Main width={["full", "full", "2 / 3"]}>
            {/* Content */}
            <Flex flexWrap="wrap">
              <Box width="full">
                <Box
                  dangerouslySetInnerHTML={{
                    __html: page.contentNode.childMarkdownRemark.html,
                  }}
                />
              </Box>
            </Flex>
          </Main>

          <Box
            as="aside"
            id="sidebar"
            bg="lightgray"
            width={["full", "full", 1 / 3]}
          >
            <Box p={[3, 3, 6]}>
              <Box as="h3">Contact Info</Box>
              {page.address.map(({ ...address }) => (
                <Box key={address.id}>
                  <p>
                    {address.streetAddress}
                    <br /> {address.addressLine2}
                    <br />
                    {address.city}, {address.state} {address.zipCode}
                  </p>
                </Box>
              ))}

              {page.phoneNumbers.map(({ ...phoneNumbers }) => (
                <Box key={phoneNumbers.id}>
                  <p>
                    <a href={`tel:${phoneNumbers.phoneNumber}`}>
                      {phoneNumbers.phoneNumber}
                    </a>
                  </p>
                </Box>
              ))}

              {page?.slug == "wvu-cancer-institute-mary-babb-randolph-cancer-center" &&
                <Button as="a" variant="solid" href="https://wvuhealthcare.wufoo.com/forms/m18akmqt1tp4wst/" sx={{ textDecoration: 'none' }}>Feedback</Button>
              }

              
            </Box>
          </Box>
        </Stack>
      </Container>
    </Layout>
  )
}

export default Center

export const query = graphql`
  query centerPageQuery($slug: String!) {
    datoCmsCenter(slug: { eq: $slug }) {
      id
      centerName
      address {
        addressLine2
        city
        zipCode
        streetAddress
        state
        id
      }
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      parentEntity {
        ... on DatoCmsInstitute {
          id
          instituteName
        }
      }
      phoneNumbers {
        phoneNumber
        phoneNumberTitle
        id
      }
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { ar: "1600:720", fit: "crop", crop: "focalpoint" }
        )
      }
      slug
      seo {
        description
        title
        image {
          url
        }
      }
      meta {
        status
      }
    }
  }
`
